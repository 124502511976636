<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Period </span>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_from"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <span class="blue-lcd mb-2 font-12">End Period </span>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Type </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="machType"
                                    :items="machTypes"
                                    :disabled=isMachTypeDisabled
                                    @change="(event) => getFilterMachId(event)"
                                    default="" 
                                    item-value="mach_type" 
                                    item-text="mach_type"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mach Id </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="machId"
                                    :items="machIds"
                                    default="" 
                                    item-value="mach_id" 
                                    item-text="mach_id"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Commodity </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="commodity"
                                    :items="commodities"
                                    default="" 
                                    item-value="commodity" 
                                    item-text="commodity"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6 mt-5" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Forecast Material Usage</h6>
                    <div id="container1" style="height: 640px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Forecast Material Usage',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            isMachTypeDisabled:true,
            machType: '',
            machTypes: [],
            machId: '',
            machIds: [],
            commodity: '',
            commodities: [],
            x: window.matchMedia("(max-width: 991px)"),
        }
    },
    mounted() {
        this.$store.dispatch('setOverlay', true)
        this.getFilterMachType()
        this.getFilterMachId()
        this.getFilterCommodity()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getFilterMachType(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/production/forecast-material-usage/filter-mach-type`, {},
                { headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_sales = false
                this.machTypes = res.data.data
                if(this.machTypes.length == 1){
                    this.machType = this.machTypes[0].mach_type
                    this.isMachTypeDisabled = true
                } else {
                    this.isMachTypeDisabled = false
                }
            });
        },
        async getFilterMachId(event){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/production/forecast-material-usage/filter-mach-id`, {
                    'mach_type': event,
                },
                { headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_sales = false
                this.machIds = res.data.data
            });
        },
        async getFilterCommodity(){
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/production/forecast-material-usage/filter-commodity`, {},
                { headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_sales = false
                this.commodities = res.data.data
            });
        },
        async submit(){
            
            if (this.date_from === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
              this.getData()  
            }
            
        },
        async getData(){
            this.$store.dispatch('setOverlay', true)
            const periodStart = new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"")
            const periodEnd = new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"")
            var respBody = {
                'mach_type': this.machType,
                'mach_id': this.machId,    
                'period_start': periodStart,
                'period_end': periodEnd,
                'commodity': this.commodity,
            }
            console.log(respBody)
            await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/production/forecast-material-usage`, respBody,
                { headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                if (res.status === 200) {
                    const data = res.data.data
                    const hasilprod = [];
                    const pakai = [];
                    const forecast = [];
                    // console.log(data)
                    data.forEach(item =>  {
                        hasilprod.push({
                            "label": item.label,
                            "y": parseFloat((item.hasil_prod != null ) ? item.hasil_prod : 0)
                        })
                        pakai.push({
                            "label": item.label,
                            "y": parseFloat((item.pakai != null ) ? item.pakai : 0)
                        })
                        forecast.push({
                            "label": item.label,
                            "y": parseFloat((item.forecast != null ) ? item.forecast : 0)
                        })
                    });
                    this.$store.dispatch('setOverlay', false)
                    this.getChart(hasilprod, pakai, forecast, "Hasil Produksi (Ton)", "Pakai (Kg)", "Forecast (Kg)", 'container1', 'column');
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    }
                }
            })
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);

            if (order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            var formattedValue = CanvasJS.formatNumber(Math.abs(e.value) / Math.pow(1000, order));

            return (e.value < 0 ? "-" : "") + formattedValue + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, dt3, name1, name2, name3, container, type){
            var self = this;

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Forecast Material Usage",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    // click: async function(e){
                    //     await self.getDetail(name1, e)
                    // },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2E2C2F",
                    name: name1,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#F29605",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                },
                {
                    // click: async function(e){
                    //     await self.getDetail(name2, e)
                    // },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2E2C2F",
                    name: name2,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#CF8D25",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                },
                {
                    // click: async function(e){
                    //     await self.getDetail(name2, e)
                    // },
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2E2C2F",
                    name: name3,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#CF8D25",
                    // yValueFormatString: "##0.00",
                    yValueFormatString: "#,###.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
    },
}

</script>

<style scoped>
    .tr_datatable{
        background-color: #F5F7F8 !important;
    }
</style>